import { Typography } from '@mui/material';
import React from 'react';

export const PasswordSuccess = () => {
  return (
    <>
      <Typography variant="h5" color="initial">
        Contraseña actualizada
      </Typography>
    </>
  );
};
